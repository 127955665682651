<template>
  <div>
    <div class="loginWapper">
      <!---->
      <div class="scrollWapper">
        <img
          class="bgcImg"
          width="100%"
          src="@/assets/kf_bg.png"
          draggable="false"
        />
        <div class="mlogin_content" style="padding-top: 0px">
          <div class="mainContent">
            <div class="titleCon">
              <!-- <img
                width="100%"
                class="hello"
                src="@/assets/hello.png"
                draggable="false"
              />
              <img
                class="mascot"
                width="100%"
                :src="webSetting.detail.webLogo"
                draggable="false"
              /> -->
              <div style="height: 100px">
                <div class="login_name">欢迎登录</div>
                <div class="login_text">
                  为了获得最佳购物体验，请先登录您的账号
                </div>
              </div>
            </div>

            <!-- <div style="height: 100px">
              <div class="login_name">欢迎登录</div>
              <div class="login_text">授权绑定三方账号实现一键式登录</div>
            </div> -->
            <van-divider></van-divider>

            <div class="formContent paddingCss">
              <div class="formContentbox">
                <div>
                  <div class="choiceItem">
                    <van-tabs
                      class="mlogin_tabs"
                      style="border-radius: 1rem 1rem 0 0"
                    >
                      <van-tab title="账号密码登录" v-if="setting.accountLogin">
                        <div class="wapper">
                          <el-form ref="form">
                            <el-form-item>
                              <el-input
                                v-model="loginForm.username"
                                placeholder="请输入账号"
                              ></el-input>
                            </el-form-item>

                            <el-form-item>
                              <el-input
                                v-model="loginForm.password"
                                show-password
                                placeholder="请输密码"
                              ></el-input>
                            </el-form-item>
                          </el-form>

                          <div class="option">
                            <div class="register" @click="handleRegister">
                              注册账号
                            </div>
                            <!-- <div class="forget">忘记密码</div> -->
                          </div>

                          <div style="margin-top: 20px">
                            <van-button
                              type="danger"
                              block
                              style="border-radius: 5px"
                              @click="handleLogin()"
                            >
                              登录
                            </van-button>
                          </div>
                        </div>
                      </van-tab>
                      <van-tab title="验证码登录" v-if="setting.phoneLogin">
                        <div class="wapper">
                          <el-form ref="form">
                            <el-form-item>
                              <el-input
                                placeholder="请输入手机号"
                                v-model="loginForm.phoneNumber"
                              ></el-input>
                            </el-form-item>

                            <el-form-item>
                              <el-input
                                placeholder="请输入短信验证码"
                                v-model="loginForm.smsVerificationCode"
                              >
                                <template slot="append">
                                  <el-button
                                    slot="suffix"
                                    :disabled="countdown > 0"
                                    @click="startCountdown()"
                                    style="border: none"
                                    >{{ buttonText }}</el-button
                                  >
                                </template>
                              </el-input>
                            </el-form-item>
                          </el-form>

                          <div class="option">
                            <div class="register" @click="handleRegister">
                              注册账号
                            </div>
                            <!-- <div class="forget">忘记密码</div> -->
                          </div>

                          <div style="margin-top: 20px">
                            <van-button
                              type="danger"
                              block
                              style="border-radius: 5px"
                              @click="handlePhoneLogin()"
                            >
                              登录
                            </van-button>
                          </div>
                        </div>
                      </van-tab>
                    </van-tabs>

                    <!-- 用户协议 -->
                    <div class="agreement">
                      <span style="margin-right: 10px">
                        <van-radio icon-size="16px"></van-radio>
                      </span>
                      <div class="TextContent">
                        我已认真阅读并同意
                        <span class="text" @click="dialogUseAgreement = true">
                          <span>《用户协议》</span>
                        </span>
                        <span
                          class="text"
                          @click="dialogPrivacyAgreement = true"
                        >
                          <span>《隐私政策》</span>
                        </span>
                      </div>
                    </div>

                    <!-- 快捷登录 -->
                    <div class="more-login" v-if="setting.quickLogin">
                      <!-- <span>第三方登录</span> -->
                      <van-divider>第三方登录</van-divider>

                      <div class="login-icon-box">
                        <span
                          v-for="(item, index) in quickLoginList"
                          :key="index"
                          @click="jhLoginForm(item.type)"
                        >
                          <img
                            v-if="item.type === 'qq'"
                            class="icon"
                            src="@/assets/icon/qq.svg"
                            draggable="false"
                          />

                          <img
                            v-if="item.type === 'wx'"
                            class="icon"
                            src="@/assets/icon/login_weixin.svg"
                            draggable="false"
                          />

                          <img
                            v-if="item.type === 'alipay'"
                            class="icon"
                            src="@/assets/icon/icon_zhifubao.svg"
                            draggable="false"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="用户协议"
      :visible.sync="dialogUseAgreement"
      center
      append-to-body
      width="90%"
    >
      <div v-html="xieyiObj.useAgreement"></div>
    </el-dialog>

    <el-dialog
      title="隐私协议"
      :visible.sync="dialogPrivacyAgreement"
      center
      append-to-body
      width="90%"
    >
      <div v-html="xieyiObj.privacyAgreement"></div>
    </el-dialog>

    <!-- 首页弹框 -->
    <el-dialog
      destroy-on-close
      append-to-body
      :visible.sync="loginNoticeVisible"
      class="success-dialog el_dialog_radius"
      width="90%"
    >
      <div class="success-dialog-container">
        <div class="title">系统公告</div>

        <div class="dilalog_img">
          <img class="fail" src="@/assets/image/notice_icon.png" />
        </div>

        <div class="ql-editor" v-html="loginNoticeContent" />
        <div class="footer">
          <div class="but" @click="loginNoticeVisible = false">关闭</div>
        </div>
      </div>
    </el-dialog>

    <van-popup
      v-model="WeChatLoginDialog"
      position="bottom"
      :style="{ height: '25%' }"
    >
      <div style="padding: 10px; margin-top: 10px">
        <van-button type="info" block @click="WeChatLoginJump()"
          >微信登录</van-button
        >
      </div>

      <div style="padding: 10px">
        <van-button
          type="default"
          block
          plain
          @click="WeChatLoginDialog = false"
          >取消</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import "@/assets/css/style.css";
import { Toast } from "vant";
import { getScanLogin, sendPhoneCode } from "@/api/user";
import { mapState } from "vuex";
import { getLoginNotice, getSysXieYi, getQuickLogin } from "@/api/index";

import { registerWeChat, getWeChatCode } from "@/api/user";

import { setToken, setUserId } from "@/utils/auth";

export default {
  data() {
    return {
      weChat_redirect_url: undefined,
      WeChatLoginDialog: false,
      xieyiObj: {},
      dialogPrivacyAgreement: false,
      dialogUseAgreement: false,
      loginForm: {
        username: "",
        password: "",
        checkPass: "",
        inviterCode: "",
        phoneNumber: "",
        smsVerificationCode: "",
        weChatCode: undefined,
      },
      labelPosition: "top",
      isSuccess: true,
      inviter_code: "",
      ZhangHaoLogin: true,
      YanZhengMa: false,
      countdown: 0,
      buttonText: "获取验证码",
      activeName: "",
      loginNoticeVisible: false,
      loginNoticeContent: undefined,
      quickLoginList: [],
    };
  },
  computed: {
    ...mapState({
      webSetting: (state) => state.index.webSetting,
      //查询网站系统配置
      setting: (state) => state.index.setting,
    }),
  },
  mounted() {
    // 根据后台返回的值设置默认选中的标签页
    if (this.setting.accountLogin) {
      this.activeName = 0;
    } else if (this.setting.phoneLogin) {
      this.activeName = 1;
    } else {
      this.activeName = 0;
    }
  },
  created() {
    //有推广码的话授权
    if (localStorage.getItem("inviterCode")) {
      this.loginForm.inviterCode = localStorage.getItem("inviterCode");
    }

    //加载登录公告
    this.queryLoginNotice();

    //加载协议
    this.loadXieYi();

    //加载快捷登录配置
    this.loadQuickLogin();

    //微信登录判断
    this.wxgetMobileCode();
  },
  methods: {
    /**
     *微信登录
     */
    wxgetMobileCode() {
      if (this.$route.query.code) {
        this.loginForm.weChatCode = this.$route.query.code;
        registerWeChat(this.loginForm)
          .then((res) => {
            if (res.status === 0) {
              Toast.success({
                message: "登陆成功",
              });

              const { token, user_id } = res.result;
              setToken(token);
              setUserId(user_id);
              this.$router.push("/");
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            // 无论成功失败都会执行的逻辑
          });
      } else {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          this.WeChatLogin();
        }
      }
    },

    WeChatLogin() {
      let data = {
        redirect_url: encodeURIComponent(window.location.href),
      };
      getWeChatCode(data)
        .then((res) => {
          if (res.status === 0) {
            this.WeChatLoginDialog = true;
            this.weChat_redirect_url = res.result;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          // 无论成功失败都会执行的逻辑
        });
      // const appid = "wxcf089e95e8f130b9";
      // const redirect_uri = encodeURIComponent(window.location.href);
      // const scope = "snsapi_userinfo";
      // const urls = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=${scope}&state=STATE#wechat_redirect`;

      // window.location.href = urls;
    },

    WeChatLoginJump() {
      window.location.href = this.weChat_redirect_url;
    },

    /**
     * 加载快捷登录配置
     */
    loadQuickLogin() {
      new Promise((resolve, rejust) => {
        getQuickLogin()
          .then((res) => {
            this.quickLoginList = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 获取协议
     */
    loadXieYi() {
      new Promise((resolve, rejust) => {
        getSysXieYi()
          .then((res) => {
            //主站
            this.xieyiObj = res.result;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    toYanZhengMaLogin() {
      this.ZhangHaoLogin = false;
      this.YanZhengMa = true;
    },
    toZhangHaoLogin() {
      this.ZhangHaoLogin = true;
      this.YanZhengMa = false;
    },

    /**
     * 查询首页弹框公告
     */
    queryLoginNotice() {
      getLoginNotice().then((response) => {
        if (response.result) {
          this.loginNoticeVisible = true;
          this.loginNoticeContent = response.result.content;
        }
      });
    },

    /**
     * 账号密码登录
     */
    handleLogin() {
      if (this.inviter_code) {
        this.loginForm.inviterCode = this.inviter_code;
      }

      this.$store
        .dispatch("user/login", this.loginForm)
        .then(() => {
          Toast.success({
            message: "登陆成功",
          });
          this.$router.push("/mindex");
        })
        .catch(() => {
          // this.isLoading = false;
        });
    },

    /**
     * 手机号登录
     */
    handlePhoneLogin() {
      if (this.inviter_code) {
        this.loginForm.inviterCode = this.inviter_code;
      }
      this.$store
        .dispatch("user/phoneLogin", this.loginForm)
        .then(() => {
          Toast.success({
            message: "登陆成功",
          });
          this.$router.push("/mindex");
        })
        .catch(() => {});
    },

    /**
     * 发送获取验证码
     */
    startCountdown() {
      new Promise((resolve, rejust) => {
        sendPhoneCode(this.loginForm)
          .then((res) => {
            if (res.status == 0) {
              Toast.success({
                message: "已发送",
              });

              this.countdown = 60;
              this.buttonText = `${this.countdown} 秒后重试`;

              const timer = setInterval(() => {
                if (this.countdown > 0) {
                  this.countdown--;
                  this.buttonText = `${this.countdown} 秒后重试`;
                } else {
                  clearInterval(timer);
                  this.buttonText = "获取验证码";
                }
              }, 1000);
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 聚合登录
     */
    jhLoginForm(type) {
      var data = {
        loginType: type,
      };
      new Promise((resolve, rejust) => {
        getScanLogin(data)
          .then((res) => {
            // this.domainList = res.result.list;
            if (res.result.code === 0) {
              // 获取第三方URL
              var url = res.result.url;

              // 在当前窗口中打开第三方URL
              window.location.href = url;
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 注册界面
     */

    handleRegister() {
      this.$router.push("/register");
    },

    /**
     * 返回主页
     */
    onClickLeft() {
      this.$router.push("/my");
    },
  },
};
</script>
<style>
.loginWapper {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  position: fixed;
}

.loginWapper .scrollWapper {
  height: 100%;
  overflow: hidden;
}

.loginWapper .bgcImg {
  width: 100vw;
  height: 100vh;
}

.loginWapper .bgcView {
  width: 100vw;
  height: 40vh;
  background: linear-gradient(0deg, #f41c0d 0%, #ff5731 100%);
}

.loginWapper .mlogin_content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* background-color: #ffffff; */
}

.loginWapper .mlogin_content .navback {
  height: 1rem;
}

.loginWapper .mlogin_content .navback img {
  width: 1rem;
  height: 1rem;
  margin-left: 0.625rem;
}

.loginWapper .mlogin_content .mainContent {
  margin-top: 0;
  overflow: hidden;
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.loginWapper .mlogin_content .back img {
  width: 0.75rem;
  height: 0.75rem;
}

.loginWapper .mlogin_content .titleCon {
  height: 9rem;
  position: relative;
}

.loginWapper .mlogin_content .titleCon .hello {
  width: 14rem;
  /* height: 8rem; */
  position: absolute;
  left: 1rem;
  bottom: 0.875rem;
}

.loginWapper .mlogin_content .titleCon .mascot {
  width: 8.5625rem;
  height: 9.4375rem;
  position: absolute;
  right: 1.625rem;
  bottom: -0.9375rem;
}

.loginWapper .mlogin_content .titleCon .name {
  margin-top: 1.15625rem;
  height: 0.96875rem;
  font-size: 1rem;
  font-family: Adobe Heiti Std;
  font-weight: 400;
  color: #433e65;
  line-height: 2.375rem;
}

.loginWapper .mlogin_content .titleCon .titleConH1 {
  font-family: TsangerFeiBai;
  font-weight: 700;
  font-size: 1.9375rem;
  color: #fff;
  line-height: 2.5625rem;
  position: absolute;
  left: 1.625rem;
  bottom: 4.375rem;
}

.loginWapper .mlogin_content .titleCon .titleConH2 {
  font-family: TsangerFeiBai;
  font-weight: 400;
  font-size: 1.5625rem;
  color: #fff;
  line-height: 2.5625rem;
  position: absolute;
  left: 1.625rem;
  bottom: 1.875rem;
}

.loginWapper .mlogin_content .formContent {
  margin-top: 0;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  border-radius: 1rem 1rem 0 0;
  z-index: 2;
}

.loginWapper .mlogin_content .formContent .formContentbox {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    transparent 0,
    transparent 90px,
    #ffffff 90px,
    #ffffff 100%
  );
}

.loginWapper .mlogin_content .formContent .choiceItem {
  border-radius: 1rem 1rem 0 0;
  background-color: rgba(255, 255, 255, 0.6);
  height: 2.8125rem;
  /* display: flex; */
  line-height: 2.8125rem;
  font-size: 0.875rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666;
}

.loginWapper .mlogin_content .formContent .choiceItem .item {
  width: 50%;
  text-align: center;
}

.loginWapper .mlogin_content .formContent .choiceItem .active {
  border-radius: 1rem 1rem 0 0;
  position: relative;
  width: 50%;
  text-align: center;
  color: #ff3000;
  background-color: #fff;
}

.loginWapper .mlogin_content .formContent .choiceItem .active:after {
  display: table;
  content: "";
  width: 1.25rem;
  height: 0.1875rem;
  background-color: #ff3000;
  transform: translate(-50%);
  position: absolute;
  left: 50%;
  bottom: 0.25rem;
}

.loginWapper .mlogin_content .formContent .wapper {
  padding: 1.875rem 1.71875rem 0;
  background-color: #fff;
}

.loginWapper .mlogin_content .formContent .wapper .formItem {
  overflow: hidden;
}

.loginWapper .mlogin_content .formContent .wapper .formItem uni-input {
  box-sizing: border-box;
  padding: 0 0.875rem;
  width: 18.75rem;
  height: 3.0625rem;
  line-height: 3.0625rem;
  background-color: #f2f6f9;
  border-radius: 0.4375rem;
  margin-bottom: 1rem;
}

uni-input {
  display: block;
  font-size: 16px;
  line-height: 1.4em;
  height: 1.4em;
  min-height: 1.4em;
  overflow: hidden;
}

uni-input[hidden] {
  display: none;
}

.uni-input-wrapper,
.uni-input-placeholder,
.uni-input-form,
.uni-input-input {
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: inherit;
}

.uni-input-wrapper,
.uni-input-form {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.uni-input-placeholder,
.uni-input-input {
  width: 100%;
}

.uni-input-placeholder {
  position: absolute;
  top: auto !important;
  left: 0;
  color: gray;
  overflow: hidden;
  text-overflow: clip;
  white-space: pre;
  word-break: keep-all;
  pointer-events: none;
  line-height: inherit;
}

.uni-input-input {
  position: relative;
  display: block;
  height: 100%;
  background: none;
  color: inherit;
  opacity: 1;
  font: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-indent: inherit;
  text-transform: inherit;
  text-shadow: inherit;
}

.uni-input-input[type="search"]::-webkit-search-cancel-button,
.uni-input-input[type="search"]::-webkit-search-decoration {
  display: none;
}

.uni-input-input::-webkit-outer-spin-button,
.uni-input-input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.uni-input-input[type="number"] {
  -moz-appearance: textfield;
}

.loginWapper .mlogin_content .formContent .wapper .option {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff3000;
}

.loginWapper .mlogin_content .formContent .wapper .login {
  margin-top: 1.875rem;
  width: 18.75rem;
  height: 2.8125rem;
  line-height: 2.8125rem;
  background: #ff3000;
  border-radius: 0.4375rem;
  font-size: 0.9375rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #fff;
}

.loginWapper .mlogin_content .formContent .wapper .or {
  margin: 1.25rem 0;
  text-align: center;
}

.loginWapper .mlogin_content .formContent .wapper .OneClickLogin {
  width: 18.75rem;
  height: 2.8125rem;
  line-height: 2.8125rem;
  background: #ffe5df;
  border-radius: 0.4375rem;
  font-size: 0.9375rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ff3000;
}

.loginWapper .mlogin_content .formContent .agreement {
  margin-left: 24px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  height: 0.9375rem;
  line-height: 0.9375rem;
  font-size: 0.75rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999;
}

.loginWapper .mlogin_content .formContent .agreement .TextContent .text {
  font-weight: 500;
  color: #3288cb;
}

.loginWapper .mlogin_content .formContent.isForgetPassword {
  background-color: #fff;
  margin-top: 2.125rem;
  width: 22.1875rem;
  margin-left: 0.625rem;
  overflow: hidden;
  border-radius: 1rem 1rem 0 0;
  padding: 0 1.71875rem;
  transform: translateY(-1.5625rem);
}

.loginWapper .mlogin_content .paddingCss {
  padding: 0 0.625rem;
}

.OneClickLoginCss {
  width: 100%;
  background: #ffffff;
  border-radius: 1.375rem 1.375rem 0 0;
  padding: 3.125rem 2.34375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.OneClickLoginCss .WeChatAvatar {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin-bottom: 0.8125rem;
}

.OneClickLoginCss .WeChatName {
  font-family: PingFang SC;
  font-weight: 700;
  font-size: 1.0625rem;
  color: #333;
  text-align: center;
  margin-bottom: 2.6875rem;
}

.OneClickLoginCss .OneClickLogin {
  width: 100%;
  height: 2.8125rem;
  background: #ff3000;
  border-radius: 0.4375rem;
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 0.9375rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mlogin_tabs .van-tabs {
  font-size: 16px;
}

.mlogin_tabs .van-tabs__nav {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 1rem 1rem 0 0;
}

.mlogin_tabs .van-tab--active {
  border-radius: 1rem 1rem 0 0;
  position: relative;
  width: 50%;
  text-align: center;
  color: #000000;
  background-color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.loginWapper .more-login {
  width: 100%;
  margin-top: 4.625rem;
  /* display: flex; */
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.15625rem;
}

.loginWapper .more-login .text {
  font-weight: 400;
  color: #9b9fa8;
  font-size: 0.875rem;
  position: relative;
}

.loginWapper .more-login .login-icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.loginWapper .more-login .text :before {
  content: "";
  width: 4.375rem;
  height: 0.03125rem;
  background: #d6dae2;
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  right: 5.125rem;
}

.loginWapper .more-login .text :after {
  content: "";
  width: 4.375rem;
  height: 0.03125rem;
  background: #d6dae2;
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  left: 5.125rem;
}

.loginWapper .more-login .login-icon-box .icon {
  width: 2.2rem;
  height: 2.2rem;
  color: #cacdd5;
}

.login_name {
  padding-left: 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  letter-spacing: 0.01rem;
  height: 60px;
  line-height: 80px;
  margin-top: 50px;
}

.login_text {
  padding-left: 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-size: 14px;
  color: #999;
  line-height: 20px;
  margin-top: 0px;
  margin-bottom: 50px;
}
</style>
