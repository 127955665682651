import request from '@/utils/request'


/**
 * 初始化网站
 * @param {*} data 
 * @returns 
 */
export function install(data) {
    return request({
        url: '/api/install',
        method: 'post',
        data
    })
}



export function weChatlogin(data) {
    return request({
        url: '/api/user/weChatlogin',
        method: 'post',
        data
    })
}


/**
 * 获取网站基础信息
 * @param {*} data 
 * @returns 
 */
export function getWebSetting(data) {
    return request({
        url: '/api/index',
        method: 'post',
        data
    })
}



/**
 * 获取网站
 * @param {*} data 
 * @returns 
 */
export function getSetting(data) {
    return request({
        url: '/api/setting',
        method: 'post',
        data
    })
}



/**
 * 获取首页弹框信息
 * @param {*} data 
 * @returns 
 */
export function getIndexNotice() {
    return request({
        url: '/api/getIndexNotice',
        method: 'post',
    })
}



/**
 * 获取登录弹框信息
 * @param {*} data 
 * @returns 
 */
export function getLoginNotice() {
    return request({
        url: '/api/getLoginNotice',
        method: 'post',
    })
}



/**
 * 获取分站弹框信息
 * @param {*} data 
 * @returns 
 */
export function getVipNotice() {
    return request({
        url: '/api/getVipNotice',
        method: 'post',
    })
}




/**
 * 获取系统公告
 * @param {*} data 
 * @returns 
 */
export function queryNoticeList(data) {
    return request({
        url: '/api/index/queryNoticeList',
        method: 'post',
        data
    })
}


/**
 * 获取首页头部导航
 * @param {*} data 
 * @returns 
 */
export function getHeadNav() {
    return request({
        url: '/api/getHeadNav',
        method: 'post',
    })
}






/**
 * 获取网站模板信息
 * @param {*} data 
 * @returns 
 */
export function getWebTemplate(data) {
    return request({
        url: '/api/webTemplate',
        method: 'post',
        data
    })
}




/**
 * 获取客服配置
 * @param {*} data 
 * @returns 
 */
export function getCustomerSetting(data) {
    return request({
        url: '/api/setting/customer/detail',
        method: 'post',
        data
    })
}


/**
 * 获取网站域名
 * @param {*} data 
 * @returns 
 */
export function getDomain(data) {
    return request({
        url: '/api/setting/config/domain',
        method: 'post',
        data
    })
}




/**
 * 热门搜索关键词
 * @param {*} data 
 * @returns 
 */
export function queryHotKeywords(data) {
    return request({
        url: '/api/hot_keywords',
        method: 'post',
        data
    })
}

/**
 * 查询商品分类
 * @param {*} data 
 * @returns 
 */
export function queryProducCategorytList(data) {
    return request({
        url: '/api/product_category/list',
        method: 'post',
        data
    })
}


/**
 * 查询轮播图
 * @param {*} data 
 * @returns 
 */
export function queryBannerList(data) {
    return request({
        url: '/api/banner_list',
        method: 'post',
        data
    })
}


/**
 * 查询菜单
 * @param {*} data 
 * @returns 
 */
export function queryMenuList(data) {
    return request({
        url: '/api/menu/list',
        method: 'post',
        data
    })
}


// /**
//  * 查询首页滚动资讯
//  * @param {*} data 
//  * @returns 
//  */
// export function queryNewList(data) {
//     return request({
//         url: '/api/normal_news_list',
//         method: 'post',
//         data
//     })
// }


/**
 * 根据商品分类id查询商品列表信息
 * @param {*} data 
 * @returns 
 */
export function queryProductList(data) {
    return request({
        url: '/api/product_list',
        method: 'post',
        data
    })
}





/**
 * 根据品牌ID查询商品列表
 * @param {*} data 
 * @returns 
 */
export function queryProductListByBrandId(data) {
    return request({
        url: '/api/product_list/brand_id',
        method: 'post',
        data
    })
}




/**
 * 根据分类ID查询品牌列表
 * @param {*} data 
 * @returns 
 */
export function getBrandListByCategoryId(data) {
    return request({
        url: '/api/brand_list/category_id',
        method: 'post',
        data
    })
}


/**
 * 根据商品id查询商品详情信息
 * @param {*} data 
 * @returns 
 */
export function queryProductDetail(data) {
    return request({
        url: '/api/product_detail',
        method: 'post',
        data
    })
}




/**
 * 前台获取推荐产品列
 * @param {*} data 
 * @returns 
 */
export function queryRecommendList(data) {
    return request({
        url: '/api/product_list/recommend',
        method: 'post',
        data
    })
}




/**
 * 前台获取热卖产品列表
 * @param {*} data 
 * @returns 
 */
export function queryHotList(data) {
    return request({
        url: '/api/product_list/hot',
        method: 'post',
        data
    })
}


/**
 * 查询首页品牌导航
 * @param {*} data 
 * @returns 
 */
export function queryIndexBrandList(data) {
    return request({
        url: '/api/index_brand/list',
        method: 'post',
        data
    })
}


/**
 * 查询支付通道
 * @param {*} data 
 * @returns 
 */
export function queryPayConfig(data) {
    return request({
        url: '/api/setting/payConfig/detail',
        method: 'post',
        data
    })
}


/**
 * 查询余额充值支付通道
 * @param {*} data 
 * @returns 
 */
export function queryRechargeConfig(data) {
    return request({
        url: '/api/setting/rechargeConfig/detail',
        method: 'post',
        data
    })
}



/**
 * 前台获取资讯列表
 * @param {*} data 
 * @returns 
 */
export function queryNormalNewsList(data) {
    return request({
        url: '/api/normal_news_list',
        method: 'post',
        data
    })
}



/**
 * 查询系统协议
 * @param {*} data 
 * @returns 
 */
export function getSysXieYi() {
    return request({
        url: '/api/setting/sysXieYi',
        method: 'post',
    })
}


/**
 * 查询快捷登录
 * @param {*} data 
 * @returns 
 */
export function getQuickLogin() {
    return request({
        url: '/api/setting/queryQuickLogin',
        method: 'post',
    })
}


/**
 * 跨域查询图片
 * @param {*} data 
 * @returns 
 */
export function cross(data) {
    return request({
        url: '/api/cross',
        method: 'post',
        data
    })
}